module.exports = {
  siteTitle: 'Shivdatt Pandya | Digital Producer',
  siteDescription:
    'Shivdatt Pandya is a digital producer with experience in development, creative direction, digital marketing, web analytics, project management and leading production.',
  siteKeywords:
    'Shivdatt Pandya, Shivdatt, Pandya, Shiv, digital producer, product owner, AEM, content producer, customer experience, agile, sydney, australia',
  siteUrl: 'https://shivdattpandya.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-175433305-1',
  googleVerification: 'RHi69Fl9lmwJYHlIE7f_TDtojCunsRMKy7SooNbIa_8',
  name: 'Shivdatt Pandya',
  location: 'Sydney, AU',
  email: 'hello@shivdattpandya.com',
  socialMedia: [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/shivdatt-pandya/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/shivdatt/',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Blog',
      url: '/posts',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
